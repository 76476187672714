import { useUserRegionCH } from '@cdc-internal/mkt-nav-footer'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { eventsLanguages } from '@/i18n/config'
import { getBrowserPrefLang } from '@/modules/utils'

import Article from '../screens/article'

const Product = (props) => {
  const article = props.pageContext.article
  const { i18n } = useTranslation()

  const userRegion = useUserRegionCH()

  useEffect(() => {
    let isExchangeCat = article.categories.nodes.some(
      (cat) => cat.slug === 'exchange-product-news-en'
    )
    if (userRegion === 'FR' && isExchangeCat) {
      window.location.pathname = `/404`
    }

    let isPayCat = article.categories.nodes.some(
      (cat) => cat.slug === 'pay-product-news-en'
    )
    if (userRegion === 'AE' && isPayCat) {
      window.location.pathname = `/404`
    }
  }, [userRegion, article.categories.nodes])

  useEffect(() => {
    const allAvaLangs = eventsLanguages.map((lang) => lang.code)
    const lang = getBrowserPrefLang(allAvaLangs)
    if (lang && lang !== i18n.language) {
      const path =
        eventsLanguages.find((item) => item.code === lang)?.path ?? ''
      navigate(`/product-news${path}/${article.slug}`)
    }
  }, [article.lang, article.slug, i18n])

  return <Article {...props} article={article} category="product-news" />
}

export default Product
